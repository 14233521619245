import React from "react"
import Layout from "../components/layout"

const ContactPage = () => (
  <Layout title="Contact us">
    <p>
      Siteware bv<br/>
      VAT: BE 0887 660 559
    </p>
    <p>
      Bist 58<br/>
      BE-2500, Lier - Belgium
    </p>
    <p>
      tel.: +32 (0)3 887 9991<br/>
      mob.: +32 (0) 473 96 84 16
    </p>
    <p>email: info@siteware.be</p>
  </Layout>
)

export default ContactPage
